import React from 'react';
import PropTypes from 'prop-types';
import DateService from '../services/DateService.js';
import Meal from './Meal.js';
import './Day.scss';

function Day(props) {
  let dayKey = DateService.getDayKey(props.day);
  return (
    <div className={'day '+props.statusweek+'-day '+props.statusday+'-day'}>
      <div className="day-wrapper">

        <div className="day-name">{props.day.toLocaleDateString("fr-FR", { weekday: 'long' })}</div>
        <div className="day-date">{props.day.toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric' })}</div>

        { props.statusweek === 'current' ? (
          <div className="meals meals-editable">
            { props.meals && props.meals[dayKey] && props.meals[dayKey].length>0 ? (
              <>
              { props.meals[dayKey].map((meal,index) => <Meal key={index} meal={meal} isEditable={true} onClick={() => props.onClickMeal(index)} />) }
               <Meal isEditable={true} onClick={() => props.onClickMeal(props.meals[dayKey].length)} />
              </>
            ) : (
            <Meal isEditable={true} onClick={() => props.onClickMeal(0)} />
            )}
          </div>
        ) : (
          <div className="meals">
            { props.meals && props.meals[dayKey] && props.meals[dayKey].length>0 ? (
              <>
              { props.meals[dayKey].map((meal,index) => <Meal key={index} meal={meal} isEditable={false}/>) }
              </>
            ) : (
            <Meal />
            )}
          </div>
        )}

      </div>
    </div>
  );
}

Day.propTypes = {
  day: PropTypes.instanceOf(Date),
  meals: PropTypes.arrayOf(PropTypes.object),  // interface I_Meal
  statusweek: PropTypes.oneOf('previous','current','next'),
  statusday: PropTypes.oneOf('pasted','today','futur'),
  onClickMeal: PropTypes.func
};


export default Day;
