import React from 'react';
import PropTypes from 'prop-types';
import DateService from '../services/DateService.js';
import Day from './Day.js';
import './Week.scss';

function Week(props) {

  return (
    <div className={'week '+props.status+'-week'}>
      { props.week.map((day,indexDay) => (
        <Day key={indexDay} day={day} meals={props.meals} 
          statusweek={props.status} 
          statusday={DateService.getDayStatus(day)}
          onClickMeal={(indexMeal) => props.onClickMeal(day,indexMeal)}/>
      )) }
    </div>
  );
}

Week.propTypes = {
  status: PropTypes.oneOf('previous','current','next'),
  week: PropTypes.arrayOf(Date),
  meals: PropTypes.object,
  onClickMeal: PropTypes.func
};


export default Week;
