import React from 'react';
import './App.scss';
import Modal from './modals/Modal.js';
import EditMeal from './modals/EditMeal.js';
import Week from './elements/Week.js';
import MealsList from './modals/MealsList.js';
import Help from './modals/Help.js';
import DateService from './services/DateService.js';
import MealsService from './services/MealsService.js';
import MiscService from './services/MiscService.js';
import ConfigService from './services/ConfigService.js';
import pkg from '../package.json';
import S_Meal from './Shapes.js';

class App extends React.Component<any,any> {

  appVersion:string='';
  appLastUpdate:string='';

  constructor(props: any) {
    super(props);
    this.state = {
      modal : false,
      modalComponent : undefined,
      weeks: {previous:[],current:[],next:[]},
      meals: {},
      mealModels: {},
      displayModelsButton: false,
      config: {}
    };
    this.toggleModal           = this.toggleModal.bind(this);
    this.toggleModal_editMeal  = this.toggleModal_editMeal.bind(this);
    this.toggleModal_mealsList = this.toggleModal_mealsList.bind(this);
    this.toggleModal_help      = this.toggleModal_help.bind(this);
    this.previous              = this.previous.bind(this);
    this.next                  = this.next.bind(this);
    this.today                 = this.today.bind(this);
    this.removeMealFromModels  = this.removeMealFromModels.bind(this);
    this.changeConfigDaysAgo   = this.changeConfigDaysAgo.bind(this);

  }

  componentDidMount() {
    this.appVersion = pkg.version;
    this.appLastUpdate = pkg.lastUpdate;
    MiscService.watchForHover();

    console.log('');
    console.log('');
    console.log('ENV',process.env.REACT_APP_ENV);
    console.log('pkg version',this.appVersion);
    console.log('pkg last update',this.appLastUpdate);


    DateService.setCurrentWeeks();
    MealsService.loadMeals();
    MealsService.loadMealModels();
    ConfigService.loadConfig();

    // auto clean
    if(ConfigService.config && ConfigService.config.autoCleanBefore && ConfigService.config.autoCleanBefore>=0){
      var dayAgo = DateService.getDaysAgo(ConfigService.config.autoCleanBefore);
      MealsService.cleanMealsBefore(DateService.getDayKey(dayAgo));
    }
    

    this.setState({
      weeks : DateService.weeks,
      meals : MealsService.meals,
      mealModels : MealsService.getMealModelsAsArray(),
      displayModelsButton : Object.keys(MealsService.meals).length>0 || MealsService.getMealModelsAsArray().length>0,
      config: ConfigService.config
    });
  }


  toggleModal() {
    this.setState((state) => ({
      modal : !state.modal,
      onDaysAgoChangeDone : false
    }));
  }

  toggleModal_editMeal(day:Date, index:number) {
    // console.log('Toggle modal EditMeal :',day,index);
    this.setState((state) => ({
      modal : !state.modal,
      modalComponent : this.componentEditMeal(day, index)
    }));
  }

  toggleModal_mealsList() {
    this.setState((state) => ({
      modal : !state.modal,
      modalComponent : this.componentMealsList()
    }));
  }

  toggleModal_help() {
    this.setState((state) => ({
      modal : !state.modal,
      modalComponent : this.componentHelp(),
      onDaysAgoChangeDone : false
    }));
  }

  previous() {
    DateService.setPreviousWeeks();
    this.setState({
      weeks : DateService.weeks
    });
  }

  next() {
    DateService.setNextWeeks();
    this.setState({
      weeks : DateService.weeks
    });
  }

  today() {
    DateService.setCurrentWeeks();
    this.setState({
      weeks : DateService.weeks
    });
  }

  saveMeal(day:Date, index:number, meal:S_Meal) {
    // console.log('Save meal : ',day,index,meal);
    let dayKey = DateService.getDayKey(day);
    MealsService.saveMeal(dayKey, index, meal);

    this.setState({
      meals : MealsService.meals,
      mealModels : MealsService.getMealModelsAsArray(),
      modal : false,
      displayModelsButton : Object.keys(MealsService.meals).length>0 || MealsService.getMealModelsAsArray().length>0
    });
  }

  removeMealFromModels(meal:S_Meal){
    console.log('Remove meal from models : ',meal);
    MealsService.removeMealFromModels(meal);

    this.setState({
      mealModels : MealsService.getMealModelsAsArray(),
      displayModelsButton : Object.keys(MealsService.meals).length>0 || MealsService.getMealModelsAsArray().length>0
    },() => {
      this.setState({
        modalComponent : this.componentMealsList()  // MAJ du contenu du modal
      });
    });
  }

  changeConfigDaysAgo(e){
    console.log('Changed config daysAgo : ',e.target.value);
    var daysAgo = e.target.value;

    if(!daysAgo || daysAgo==='-'){
      ConfigService.setConfig_autoCleanBefore(undefined);
    }else{
      var daysAgo_asInt = parseInt(daysAgo);
      ConfigService.setConfig_autoCleanBefore(daysAgo_asInt);
    }

    this.setState({
      config : ConfigService.config,
      onDaysAgoChangeDone : true
    },() => {
      this.setState({
        modalComponent : this.componentHelp()  // MAJ du contenu du modal
      });
    });
  }


  componentEditMeal(day:Date, index:number) {
    let dayKey = DateService.getDayKey(day);

    let meal = undefined;
    if(this.state.meals && dayKey && index!==undefined && index<=0 && this.state.meals[dayKey] && this.state.meals[dayKey][index])
      meal = this.state.meals[dayKey][index];

    return <EditMeal day={day} index={index} meal={meal} mealModels={this.state.mealModels}  onSubmit={(meal) => this.saveMeal(day, index, meal)} onAbort={this.toggleModal}/>;
  }

  componentMealsList() {
    return <MealsList meals={this.state.mealModels} onRemoveMeal={this.removeMealFromModels}/>;
  }

  componentHelp() {
    return <Help handleAbort={this.toggleModal} onDaysAgoChange={this.changeConfigDaysAgo} config={this.state.config} onDaysAgoChangeDone={this.state.onDaysAgoChangeDone}/>;
  }

  render() {
    console.log('%cApp State%c','color:grey','color:inherit',this.state);

    let formatDateAsName = { weekday: 'long' };
    let formatDateAsDate = { year: 'numeric', month: 'short', day: 'numeric' };

    return (
      <div className={'App' + (this.state.modal ? ' modal-opened' : '')}>

        <div className="page">
          <div className="weekly">

            <div className="button button-previous" role="button" onClick={this.previous} title="Semaine pr&eacute;c&eacute;dante"></div>

            { this.state.weeks.previous ? (
            <Week status="previous" week={this.state.weeks.previous} meals={this.state.meals}></Week>
            ) : null}


            { this.state.weeks.current ? (
            <Week status="current" week={this.state.weeks.current} meals={this.state.meals} onClickMeal={(day,indexMeal) => this.toggleModal_editMeal(day,indexMeal)}></Week>
            ) : null}


            { this.state.weeks.next ? (
            <Week status="next" week={this.state.weeks.next} meals={this.state.meals}></Week>
            ) : null}

            <div className="button button-next" role="button" onClick={this.next} title="Semaine suivante"></div>

          </div>

          <div className="menu">
            <div className="button button-menu" onClick={this.today} tabIndex="0" role="button" title="Revenir à aujourd'hui">Aujourd'hui</div>
            { this.state.displayModelsButton ? (
              <div className="button button-menu" onClick={this.toggleModal_mealsList} tabIndex="0" role="button" title="Voir tous les modèles de plats">Modèles</div>
            ) : null }
            <div className="button button-menu" onClick={this.toggleModal_help} tabIndex="0" role="button" title="Voir A propos">?</div>
          </div>

          <div className="version">
            { process.env.REACT_APP_ENV === 'development' ? 'dev' : ''}
            <br/>{this.appVersion}<br/>{this.appLastUpdate}
          </div>
        </div>

        { this.state.modal ? (
        <Modal onClickOverlay={this.toggleModal}>
          {this.state.modalComponent}
        </Modal>
        ) : null}

      </div>
    );
  }
}

export default App;
