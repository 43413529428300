let STORAGE_KEY_MEALS = 'menusweekly_meals';
let STORAGE_KEY_MEALMODELS = 'menusweekly_mealmodels';

interface I_Meal {
  label: string;
  bgColor: string;
};

let MealsService = {
  meals: {},               // { string (dayKey) : []<I_Meal> }
  mealModelsByLabel: {},    // { string (label)  : I_Meal }

  loadMeals: function() {
    try{
      var res = window.localStorage[STORAGE_KEY_MEALS];
      if(!res)
        return;
      MealsService.meals = JSON.parse(res);

      console.log('%cLoaded and set meals%c','font-weight:bold','font-weight:normal',MealsService.meals);
    }catch(err){
      console.error('Cannot load meals : ',err);
    }
  },
  loadMealModels: function() {
    try{
      var res = window.localStorage[STORAGE_KEY_MEALMODELS];
      if(!res)
        return;
      MealsService.mealModelsByLabel = JSON.parse(res);

      console.log('%cLoaded and set meal models%c','font-weight:bold','font-weight:normal',MealsService.mealModelsByLabel);
    }catch(err){
      console.error('Cannot load meal models : ',err);
    }
  },

  // supprime toutes les entrees de menus anterieure au dayKey
  cleanMealsBefore: function(dayKey:string){
    try{
      var keys = Object.keys(MealsService.meals);
      if(!keys || keys.length===0)
        return;

      if(!dayKey)
        return;

      var dayKey_asInt = parseInt(dayKey);

      var changed = false;
      for(var i = 0; i < keys.length; i++){
        var mealDayKey = keys[i];
        var mealDayKey_asInt = parseInt(mealDayKey);
        if(mealDayKey_asInt < dayKey_asInt){
          delete MealsService.meals[mealDayKey_asInt];
           changed = true;
        }
      }

      if(changed){
        window.localStorage[STORAGE_KEY_MEALS] = JSON.stringify(MealsService.meals);
        console.log('%cCleaned meals%c','font-weight:bold','font-weight:normal','Cleaned.',MealsService.meals);
      }else{
        console.log('%cCleaned meals%c','font-weight:bold','font-weight:normal','Not changed.',MealsService.meals);
      }
    }catch(err){
      console.error('Cannot clean meals before : ',err);
    }
  },

  getMeal: function(dayKey:string,index:number){
    try{
      if(!dayKey || index===undefined || index<0)
        return;
      if(!MealsService.meals[dayKey])
        return;
      return MealsService.meals[dayKey][index];
    }catch(err){
      console.error('Cannot get meal : ',err);
    }
  },

  // si meal est defini et a un label, l'ajoute a l'index donne,
  // sinon, supprime l'index donne
  saveMeal: function(dayKey:string,index:number,meal:I_Meal) {
    try{
      // validation des donnees
      if(meal && meal.label && meal.label.trim()==='')
        meal = undefined;

      if(!meal){
        console.log('➖ Remove meal on',dayKey,index);
        // suppression du meal si l'index existe
        if(MealsService.meals[dayKey] && MealsService.meals[dayKey].length >= index + 1)
          MealsService.meals[dayKey].splice(index,1);
        // suppression du day si il est vide
        if(MealsService.meals[dayKey] && MealsService.meals[dayKey].length === 0)
          delete MealsService.meals[dayKey];

      }else{
        console.log('➕ Add/Update meal on',dayKey,index,meal);
        // creation du day si inexistance
        if(!MealsService.meals[dayKey])
          MealsService.meals[dayKey] = [];
        // ajout du meal
        MealsService.meals[dayKey][index] = meal;

        // models
        MealsService.saveMealAsModel(meal);

      }
      // console.log('Meals',MealsService.meals);

      window.localStorage[STORAGE_KEY_MEALS] = JSON.stringify(MealsService.meals);
      console.log('%cSaved meals%c','font-weight:bold','font-weight:normal',MealsService.meals);
    }catch(err){
      console.error('Cannot save meal : ',err);
    }
  },

  saveMealAsModel: function(meal:I_Meal){
    try{
      // validation des donnees
      if(!meal || !meal.label || meal.label.trim()==='')
        return;

      const key = meal.label.trim().toLowerCase();

      console.log('➕ Add/Update meal model',meal);
      MealsService.mealModelsByLabel[key] = meal;

      window.localStorage[STORAGE_KEY_MEALMODELS] = JSON.stringify(MealsService.mealModelsByLabel);
      console.log('%cSaved meal models%c','font-weight:bold','font-weight:normal',MealsService.mealModelsByLabel);
    }catch(err){
      console.error('Cannot save meal as model: ',err);
    }
  },

  removeMealFromModels: function(meal:I_Meal){
    try{
      // validation des donnees
      if(!meal || !meal.label || meal.label.trim()==='')
        return;

      const key = meal.label.trim().toLowerCase();

      console.log('➖ Remove meal model',meal);
      if(MealsService.mealModelsByLabel[key]){
        delete MealsService.mealModelsByLabel[key];
      }else{
        delete MealsService.mealModelsByLabel[meal.label];
      }

      window.localStorage[STORAGE_KEY_MEALMODELS] = JSON.stringify(MealsService.mealModelsByLabel);
      console.log('%cSaved meal models%c','font-weight:bold','font-weight:normal',MealsService.mealModelsByLabel);
    }catch(err){
      console.error('Cannot remove meal from models: ',err);
    }
  },


  getSuggestions: function(label:string){
    try{
      // validation des donnees
      if(label &&label.trim()==='')
        label = undefined;

      if(!label){
        return [];

      }else{
        if(!MealsService.mealModelsByLabel)
          return [];

        let modelsKeys = Object.keys(MealsService.mealModelsByLabel);
        if(modelsKeys.length===0)
          return [];

        let suggestions = [];
        for(var i = 0; i < modelsKeys.length; i++){
          let key = modelsKeys[i];
          let mealModel = MealsService.mealModelsByLabel[key];
          if(!mealModel || !mealModel.label)
            continue;

          // suggestion : contient exactement
          // TODO : approximation, casse...
          let formattedModelLabel = mealModel.label.trim().toLowerCase();
          let formattedEnteredLabel = label.trim().toLowerCase();
          if(formattedModelLabel.indexOf(formattedEnteredLabel)!==-1){
            var r = new RegExp('('+label.trim()+')', "ig");
            let mealModelLabelHighlight = mealModel.label.replace(r, '<strong>$1</strong>');
            let suggestion = {
              label: mealModel.label,
              bgColor: mealModel.bgColor,
              labelHL: mealModelLabelHighlight
            };
            suggestions.push(suggestion);
          }
        }
        return suggestions;

      }
    }catch(err){
      console.error('Cannot get suggestions for label',label,' : ',err);
    }
  },

  getMealModelsAsArray: function(){
    try{
      let mealModelsArray = [];
      if(MealsService.mealModelsByLabel){
        let keys = Object.keys(MealsService.mealModelsByLabel);
        if(keys.length>0){
          keys.sort();
          for(var i = 0; i < keys.length; i++){
            let meal = MealsService.mealModelsByLabel[keys[i]];
            mealModelsArray.push(meal);
          }
        }
      }
      return mealModelsArray;
    }catch(err){
      console.error('Cannot get meal models as array: ',err);
    }
  }
};
export default MealsService;