import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

console.log(process.env.REACT_APP_ENV);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if(process.env.REACT_APP_ENV==='development'){
  serviceWorker.unregister();
}else{
  serviceWorker.register({
    onUpdate: function(registration){
      window.location.reload();
    }
  });
}
