import React from 'react';
import PropTypes from 'prop-types';
import './Help.scss';
import S_Config from '../Shapes.js';

function Help(props) {

  return (
    <div className="Help">
      <h2>
        <span className="">A propos</span>
      </h2>
      <div className="help-descr">
        <p>Cette page est cr&eacute;&eacute;e et maintenue &agrave; titre personnel, et rendue publique pour qu'elle profite &agrave; d'autres.</p>
        <p>L'ensemble des données est sauvegardé en "localStorage" sur le navigateur. Il n'y a pas de partage de données entre différents navigateurs / appareils. Un vidage des données du navigateur entraine la perte des données sans possibilité de récupération.</p>
        <label for="daysAgo">Supprimer les données antérieures à (suppression immédiate, et automatiquement à chaque chargement de l'appli) :</label>
        <div className="row">
          <div className="field">
            <select name="daysAgo" id="daysAgo" onChange={(e) => props.onDaysAgoChange(e)}>
              <option value="-" selected={props.config.autoCleanBefore==undefined || props.config.autoCleanBefore=='-'}>Ne pas supprimer</option>
              <option value="61" selected={props.config.autoCleanBefore!=undefined && props.config.autoCleanBefore==61}>2 mois</option>
              <option value="183" selected={props.config.autoCleanBefore!=undefined && props.config.autoCleanBefore==183}>6 mois</option>
              <option value="366" selected={props.config.autoCleanBefore!=undefined && props.config.autoCleanBefore==366}>1 an</option>
            </select>
            { props.onDaysAgoChangeDone ? <span className="done">Compris!</span> : null }
          </div>
        </div>
        <p>Cette page fait l'objet d'un tracking basique via le service Google Analytics, afin de conna&icirc;tre sa fr&eacute;quentation.</p>
      </div>
      <div className="ok" tabIndex="0" onClick={props.handleAbort}>OK</div>
    </div>
  );
}

Help.propTypes = {
  handleAbort: PropTypes.func,
  onDaysAgoChange: PropTypes.func,
  config: S_Config,
  onDaysAgoChangeDone: PropTypes.bool,
};


export default Help;
