import PropTypes from 'prop-types';

let S_Meal = PropTypes.shape({
  label: PropTypes.string,
  bgColor: PropTypes.string
});

let S_Config = PropTypes.shape({
  autoCleanBefore: PropTypes.number
});

export default [S_Meal,S_Config];