import React from 'react';
import PropTypes from 'prop-types';
import DateService from '../services/DateService.js';
import MealsService from '../services/MealsService.js';
import S_Meal from '../Shapes.js';
import './Meal.scss';

function Meal(props) {
  if(!props.meal || !props.meal.label){
    if(props.isEditable)
      return (
        <div className="meal meal-addable" onClick={props.onClick} tabIndex="0" role="button" title="Ajouter un plat">
          Ajouter
        </div>
      );

    return (
      <div className="meal meal-empty">-</div>
    );
  }

  if(props.isEditable)
    return (
      <div className="meal meal-editable" onClick={props.onClick} tabIndex="0" role="button" title="Modifier le plat">
        <span className="meal-label">{props.meal.label}</span>
      </div>
    );

  return (
    <div className="meal meal-not-editable">
      <span className="meal-label">{props.meal.label}</span>
    </div>
  );
}

Meal.propTypes = {
  meal: S_Meal,
  isEditable: PropTypes.bool
};


export default Meal;
