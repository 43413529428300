let STORAGE_KEY_CONFIG = 'menusweekly_config';

interface I_Config {
  autoCleanBefore: number
};

let ConfigService = {
  config: {
    autoCleanBefore: undefined  // number of days ago
  },

  loadConfig: function() {
    try{
      var res = window.localStorage[STORAGE_KEY_CONFIG];
      if(!res)
        return;
      ConfigService.config = JSON.parse(res);

      console.log('%cLoaded and set config%c','font-weight:bold','font-weight:normal',ConfigService.config);
    }catch(err){
      console.error('Cannot load config : ',err);
    }
  },

  setConfig_autoCleanBefore: function(autoCleanBefore:number){
    try{
      ConfigService.config.autoCleanBefore = autoCleanBefore;
      window.localStorage[STORAGE_KEY_CONFIG] = JSON.stringify(ConfigService.config);
      console.log('%cSaved config%c','font-weight:bold','font-weight:normal',ConfigService.config);
    }catch(err){
      console.error('Cannot set config autoCleanBefore',autoCleanBefore,' : ',err);
    }
  }
  
};
export default ConfigService;