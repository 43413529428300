import React from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';

function Modal(props) {
  return (
    <div className="Modal">
      <div className="modal-overlay" onClick={props.onClickOverlay}></div>
      <div className="modal-window">
        {props.children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  onClickOverlay: PropTypes.func
};


export default Modal;
