import React from 'react';
import PropTypes from 'prop-types';
// import MealsService from '../services/MealsService.js';
import S_Meal from '../Shapes.js';
import './MealsList.scss';

function MealsList(props) {

  

  return (
    <div className="meals-list">
      <h2>
        <span className="">Mes modèles</span>
      </h2>

      { props.meals.length===0 ? (
      <div className="list empty">
      <div className="descr">Aucun modèle.<br/>Des modèles sont ajoutés automatiquement à chaque validation d'un nouveau plat dans le semainier.</div>
      </div>
      ) : null }

      { props.meals.length>0 ? (
      <div className="list not-empty">
        { props.meals.map((meal,indexMeal) => (
          <div key={indexMeal} className="meal-model">
            <div className="meal-model-wrapper">
              <div className="label">{meal.label}</div>
              <div className="button button-remove-meal" role="button" title="Supprimer" onClick={() => props.onRemoveMeal(meal)} tabIndex="0">Supprimer</div>
            </div>
          </div>
        )) }
      </div>
      ) : null }
    </div>
  );
}

MealsList.propTypes = {
  meals: PropTypes.arrayOf(S_Meal),
  onRemoveMeal: PropTypes.func
};


export default MealsList;
