
const A_DAY_IN_MILLIS = 24*60*60*1000;
var getMonday = function(){
  var date = new Date();
  var dayInWeek = date.getDay();
  var dateInMillis = date.getTime();
  console.log('We are...',date);
  var monday;
  if(dayInWeek !== 1){  // NB 0=sun 1=mon
    let deltaInMillis = (dayInWeek-1) * (24*60*60*1000);
    monday = new Date(dateInMillis - deltaInMillis);
    console.log('Monday is...',monday);
  }else{
    monday = date;
  }
  if(monday)
    return monday;
  return undefined;
};
var getWeeks = function(monday:?Date){
  try{
    if(!monday)
      monday = getMonday();
    if(!monday)
      return;

    let lastMondayInMillis = monday.getTime();
    // console.log('monday',monday);


    let currentWeek = [];
    for (let i = 0; i < 7; i++) {
      let deltaInMillis = i * A_DAY_IN_MILLIS;
      let date = new Date(lastMondayInMillis + deltaInMillis);
      currentWeek.push(date);
    }
    // console.log('Current',currentWeek);

    let previousWeek = [];
    for (let i = 0; i < 7; i++) {
      let deltaInMillis = i * A_DAY_IN_MILLIS;
      let deltaWeekInMillis = 7 * A_DAY_IN_MILLIS;
      let date = new Date(lastMondayInMillis + deltaInMillis - deltaWeekInMillis);
      previousWeek.push(date);
    }
    // console.log('Previous',previousWeek);

    let nextWeek = [];
    for (let i = 0; i < 7; i++) {
      let deltaInMillis = i * A_DAY_IN_MILLIS;
      let deltaWeekInMillis = 7 * A_DAY_IN_MILLIS;
      let date = new Date(lastMondayInMillis + deltaInMillis + deltaWeekInMillis);
      nextWeek.push(date);
    }
    // console.log('Next',nextWeek);

    let weeks = {
      previous: previousWeek,
      current: currentWeek,
      next: nextWeek
    };
    // console.log('Weeks:',weeks);

    return weeks;
  }catch(err){
    console.error('Cannot read weeks : ',err);
  }
};


let DateService = {



  weeks: {},

  // positionne les semaines sur la semaine courante
  setCurrentWeeks: function(){
    try{
      var monday = getMonday();
      console.log('Monday:',monday);
      var weeks;
      if(monday){
        weeks = getWeeks(monday);
        console.log('weeks:',weeks);
      }
      if(weeks)
        DateService.weeks = weeks;

      console.log('Set current weeks:',DateService.weeks);
    }catch(err){
      console.error('Cannot set current weeks : ',err);
    }
  },
  // positionne les semaines sur la semaine suivante
  setNextWeeks: function(){
    try{
      if(!DateService.weeks || !DateService.weeks.next || DateService.weeks.next.length===0)
        return;
      var weeks = getWeeks(DateService.weeks.next[0]);
      if(weeks)
        DateService.weeks = weeks;

      console.log('Set next weeks:',DateService.weeks);
    }catch(err){
      console.error('Cannot set next weeks : ',err);
    }
  },
  // positionne les semaines sur la semaine precedante
  setPreviousWeeks: function(){
    try{
      if(!DateService.weeks || !DateService.weeks.previous || DateService.weeks.previous.length===0)
        return;
      var weeks = getWeeks(DateService.weeks.previous[0]);
      if(weeks)
        DateService.weeks = weeks;

      console.log('Set previous weeks:',DateService.weeks);
    }catch(err){
      console.error('Cannot set previous weeks : ',err);
    }
  },
  // renvoie la cle du jour au format 20200211
  getDayKey: function(day:Date){
    try{
      if(!day)
        return;
      // console.log(day);
      var dayKey = day.toISOString().slice(0,10).replace(/-/g,"");
      return dayKey;
    }catch(err){
      console.error('Cannot get day',day,'key : ',err);
    }
  },
  // determine si le jour est courant, passe, ou futur (independemment du week)
  getDayStatus: function(day:Date){
    try{
      if(!day)
        return;
      // console.log(day);
      const today = new Date();
      var isToday = day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear();
      if(isToday)
        return 'today';
      if(day < today)
        return 'pasted';
      return 'futur';
    }catch(err){
      console.error('Cannot get day',day,'status : ',err);
    }
  },
  // get yesterday
  getYesterday: function(){
    try{
      return DateService.getDaysAgo(1);
    }catch(err){
      console.error('Cannot get yesterday : ',err);
    }
  },
  // get 60 days ago
  get60daysAgo: function(){
    try{
      return DateService.getDaysAgo(60);
    }catch(err){
      console.error('Cannot get 60 days ago : ',err);
    }
  },
  // get x days ago
  getDaysAgo: function(days:number){
    try{
      if(!days)
        return;
      var now = new Date();
      return new Date(now.getTime() - (days * A_DAY_IN_MILLIS));
    }catch(err){
      console.error('Cannot get',days,'days ago : ',err);
    }
  }
};
export default DateService;