import React from 'react';
import PropTypes from 'prop-types';
import MealsService from '../services/MealsService.js';
import S_Meal from '../Shapes.js';
import './EditMeal.scss';

class EditMeal extends React.Component<any,any> {

  constructor(props: any) {
    super(props);
    this.state = {
      mealname: '',
      suggestions: [],
      showSuggestions: true
    };
    this.handleNameChange           = this.handleNameChange.bind(this);
    this.handleSelectSuggestion     = this.handleSelectSuggestion.bind(this);
    this.handleSubmit               = this.handleSubmit.bind(this);
    this.handleAbort                = this.handleAbort.bind(this);
    this.handleEmpty                = this.handleEmpty.bind(this);
    this.handleCloseSuggestions     = this.handleCloseSuggestions.bind(this);
  }
  static defaultProps  = {
    day: PropTypes.instanceOf(Date),  // type Date
    index: PropTypes.number,          // [0-9]*
    meal: S_Meal,
    mealModels: PropTypes.arrayOf(S_Meal),
    onSubmit: PropTypes.func,
    onAbort: PropTypes.func
  }
  componentDidMount() {
    this.setState((state,props) => ({
      mealname : props.meal && props.meal.label ? props.meal.label : ''
    }));
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.meal !== this.props.meal) {
      this.setState((state,props) => ({
        mealname : props.meal && props.meal.label ? props.meal.label : ''
      }));
    }
  }





  handleNameChange(e) {
    let valueEntered = e.target.value;
    // recherche des suggestions...
    if(valueEntered.length>=2){
      let suggestions = MealsService.getSuggestions(valueEntered);
      // update render...
      this.setState({
        mealname : valueEntered,
        suggestions: suggestions,
        showSuggestions: true
      });
    }else{
      // update render...
      this.setState({
        mealname : valueEntered,
        suggestions: [],
        showSuggestions: true
      });
    }
  }

  handleSelectSuggestion(suggestion) {
    if(!suggestion || !suggestion.label)
      return;

    this.setState({
      mealname : suggestion.label,
      suggestions: [],
      showSuggestions: false
    });
  }

  handleSubmit(e) {
    if(!this.props.onSubmit)
      return;
    
    if(!this.state.mealname || this.state.mealname.trim() === ''){
      // console.log('Remove meal on',this.props.day,this.props.index);
      this.props.onSubmit(undefined);

    }else{
      // let meal = new C_Meal();
      // meal.label = this.state.name;
      let meal = {
        label : this.state.mealname
      }
      // console.log('Submit meal',meal,'on',this.props.day,this.props.index);
      this.props.onSubmit(meal);

    }
  }

  handleAbort(e){
    if(!this.props.onAbort)
      return;
    
    this.props.onAbort();
  }

  handleEmpty(e){
    this.setState({
      mealname : '',
      suggestions: [],
      showSuggestions: true
    });
  }

  handleCloseSuggestions(e){
    this.setState({
      showSuggestions: false
    });
  }

  render() {

    return (
      <div className="EditMeal">
        <h2>
          <span className="dayTitle">{this.props.day.toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}</span>
        </h2>

        <div>
          <div className="field">
            <label htmlFor="mealname">Nom du plat</label>
            <div className="fieldDescr">Laisser vide pour supprimer</div>
            <input type="text" id="mealname" placeholder="Lasagnes bolognaises" value={this.state.mealname} onChange={this.handleNameChange}/>
            { this.state.mealname !== '' ? <div className="empty" tabIndex="0" onClick={this.handleEmpty}>+</div> : null }
            { this.state.showSuggestions && this.state.suggestions && this.state.suggestions.length>0 ? (
            <div className="suggestions">
              <div className="suggestions-close" tabIndex="0" onClick={this.handleCloseSuggestions}>+</div>
              <label htmlFor="suggestion">Suggestions</label>
              <div className="fieldDescr">Sélectionner un modèle</div>
              { this.state.suggestions.map((suggestion,index) => {
                if(index > 5)
                  return null;
                return (
                  <div key={index} className="suggestion" onClick={() => this.handleSelectSuggestion(suggestion)} dangerouslySetInnerHTML={{__html: suggestion.labelHL}} tabIndex="0" role="button"></div>
                );
              }) }
            </div>
            ) : null }
            { this.state.showSuggestions && (!this.state.suggestions || this.state.suggestions.length===0) && this.props.mealModels.length>0 ? (
            <div className="suggestions suggestions-all">
              <div className="suggestions-close" tabIndex="0" onClick={this.handleCloseSuggestions}>+</div>
              <label htmlFor="suggestion">Suggestions</label>
              <div className="fieldDescr">Sélectionner un modèle</div>
              { this.props.mealModels.map((meal,index) => {
                return (
                  <div key={index} className="suggestion" onClick={() => this.handleSelectSuggestion(meal)} tabIndex="0" role="button">{meal.label}</div>
                );
              }) }
            </div>
            ) : null }
          </div>

          <div className="infos">
          A la validation, le plat sera automatiquement ajouté aux modèles de plat (si un même libellé existe déjà, il sera remplacé). Ces données sont effaçables.
          </div>

          <div className="actions">
            <div className="submit" tabIndex="0" onClick={this.handleSubmit}>Valider</div>
            <div className="abort" tabIndex="0" onClick={this.handleAbort}>Annuler</div>
          </div>
        </div>

      </div>
    );
  }
}

export default EditMeal;
